.top-bar {
    background-color: #ffefef33;
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
}

.top-bar a {
    color: white;
    text-decoration: none;
}

.top-bar > .row {
    margin: 0;
}

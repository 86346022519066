.replay-pause {
    font-size: 30px;
    position: absolute;
    bottom: 5px;
    left: -5px;
}

.replay-pause:hover {
    cursor: pointer;
}
.replay-slider {
    padding-left: 25px;
}

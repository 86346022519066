.board-container {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
}

.board {
    position: relative;
}

.game-container {
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
    padding: 20px;
    padding-top: 40px;
}

.pulse {
    animation: pulse 0.2s 2 ease-in-out alternate;
}

@keyframes pulse {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.025);
    }
}

@media (max-width: 1000px) {
    .game-container {
        flex-flow: wrap;
    }

    .board-container-wrap {
        order: 3;
        justify-content: center;
        display: flex;
        width: 100%;
    }
}

body {
    background-color: rgb(54, 54, 54);
}

.team--1-btn {
    background-color: rgb(52 52 52);
    color: rgb(226, 226, 226) !important;
}

.team-0-btn {
    background-color: rgb(226, 226, 226);
}

.team-1-btn {
    background-color: rgb(252, 107, 107);
}

.team-2-btn {
    background-color: rgb(107, 170, 252);
}

.team-1-text {
    color: rgb(252, 107, 107);
}

.team-2-text {
    color: rgb(107, 170, 252);
}

.team-1-bg {
    background-color: #402424;
}

.team-2-bg {
    background-color: #242840;
}

.unselectable {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.pointer:hover {
    cursor: pointer;
}

.empty-message {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: rgba(255, 255, 255, 0.574);
    min-width: 100%;
    min-height: 100%;
}

.match-container {
    padding: 10px;
    background-color: #ffefef17;
    margin: 10px;
    width: 100%;
    color: rgb(255, 255, 255);
}

.match-container:hover {
    cursor: pointer;
    background-color: #ffefef3c;
}

.match-container > td {
    border: solid 0px #000;
    border-style: none solid solid none;
}

.match-container > td:first-child {
    border-top-left-radius: 2px;
}
.match-container > td:last-child {
    border-top-right-radius: 2px;
}

.match-container > td:first-child {
    border-bottom-left-radius: 2px;
}
.match-container > td:last-child {
    border-bottom-right-radius: 2px;
}

.match-container > td:first-child {
    border-top-style: solid;
}
.match-container > td:first-child {
    border-left-style: solid;
}

.match-score {
    width: 65px;
}
.match-black {
    color: black;
}

.match-blue {
    color: rgb(177 177 224);
}

.match-red {
    color: rgb(208 125 125);
}

.player-win {
    color: green;
}

.player-lose {
    color: red;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.match-words-count {
    width: 60px;
}

.match-date {
    width: 280px;
}

.black-word-selected {
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    /* text-align: center; */
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 20px;
    width: 20px;
    margin-top: 2px;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.black-word-selected > i {
    color: #000000;
    font-weight: bold;
    font-size: 10px;
    padding-top: 2px;
}

.word-card {
    font-weight: bolder;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;

    -webkit-box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.6) inset;
    -moz-box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.6) inset;
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.6) inset;
    position: relative;
    text-align: center;
}

.team-unknown {
    color: white;
}

.word-guess-container {
    position: absolute;
    bottom: 0px;
}

.word-card-inactive {
    opacity: 50%;
}

@media (min-width: 1600px) {
    .word-card {
        font-size: 12.5pt;
        width: 128px;
        height: 70px;
        margin: 0.45rem !important;
    }
}

@media (max-width: 1600px) and (min-width: 1400px) {
    .word-card {
        width: 112px;
        height: 64px;
        font-size: 11pt;
        margin: 0.4rem !important;
    }
}

@media (max-width: 1400px) and (min-width: 401px) {
    .word-card {
        width: 110px;
        height: 58px;
        font-size: 10.5pt;
        margin: 0.35rem !important;
    }
}

@media (max-width: 400px) {
    .word-card {
        width: 90px;
        height: 56px;
        font-size: 10pt;
        margin: 0.3rem !important;
    }
}

@media (min-width: 901px) {
    .main-menu {
        min-width: 300px;
    }

    .main-menu-button-list {
        font-size: 25px;
        margin-top: -20px;
        padding-left: 35px;
    }
}

@media (max-width: 900px) {
    .main-menu {
        height: 150px;
        width: 100%;
    }

    .main-menu-button-list {
        display: flex;
        font-size: 18px;
        padding-left: 25px;
        padding-top: 50px;
    }

    .main-menu-button {
        padding-left: 10px;
    }
}

.main-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #ffefef17;
}

.main-menu-button {
    cursor: default;
}

.main-menu-button-active {
    color: rgb(185, 185, 185);
}

.main-menu-button:hover {
    color: rgb(185, 185, 185);
    cursor: pointer;
}

.main-menu-button:hover > .main-menu-icon {
    opacity: 100%;
}

.main-menu-site-name {
    position: absolute;
    top: 20px;
    font-size: 35px;
    padding-left: 60px;
    cursor: pointer;
}

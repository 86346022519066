.game-status {
    font-size: 14pt;
    font-weight: bolder;
    color: #ffffffbf;

    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

@media (max-width: 450px) {
    .game-status {
        min-height: 40px;
        line-height: 20px;
    }
}

@media (min-width: 451px) {
    .game-status {
        line-height: 40px;
    }
}

.team-join {
    text-decoration: underline;
}

.team-player-list {
    /* fix for team score */
    z-index: 1;
    position: relative;
}

.team-player-list p {
    margin: 2px;
    padding: 2px;
}

.disconnected {
    opacity: 50%;
}

.remove-player-icon:hover {
    cursor: pointer;
    color: rgb(80, 80, 80);
}

.player-link {
    color: black;
    text-decoration: none;
}

.player-link:hover {
    color: rgba(0, 0, 0, 0.451);
    cursor: pointer;
}

.how-to-play > h1 {
    padding: 20px;
}

.how-to-play > h4 {
    padding: 20px;
}

.how-to-play > img,
.how-to-play > div > img {
    padding-bottom: 20px;
}

.player-page-menu {
    display: inline;
    padding: 10px;
}

.player-page-menu > a {
    border-radius: 0px;
}
.player-page-menu > a:first-child {
    border-radius: 5px 0 0 5px;
}

.player-page-menu > a:last-child {
    border-radius: 0 5px 5px 0;
}

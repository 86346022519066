.game-timer {
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 24pt;
    text-transform: uppercase;
    font-weight: bolder;
    color: white;
    width: 100px;
    margin-bottom: -10px;
}

.game-timer-soon {
    color: red;
}

.game-timer-guess-taken {
    color: rgb(255, 153, 0);
}

.entry-point-container {
    display: flex;
    color: white;
    min-height: 100vh;
}

@media (min-width: 901px) {
    .entry-point-content-wrap {
        padding-left: 50px;
        height: 100%;
        padding-top: 40px;
        padding-right: 20px;
        top: 0;
        right: 0;
        left: 300px;
        bottom: 0;
    }
}

@media (max-width: 900px) {
    .entry-point-content-wrap {
        padding-left: 50px;
        /* height: 100%; */
        padding-top: 20px;
        padding-right: 20px;
        top: 150px;
        right: 0;
        left: 0;
        bottom: 0;
    }
}

.entry-point-content-wrap {
    overflow-y: auto;
    position: fixed;
}

.entry-point-user-section {
    background-color: #ffefef33;
    color: white;
    position: absolute;
    top: 10px;
    right: 30px;
    left: 0;
}

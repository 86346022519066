.main-menu-icon {
    padding-right: 5px;
    font-size: 15px;
    position: relative;
    bottom: 2px;
}

.hidden {
    opacity: 0%;
}

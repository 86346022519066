.loader-container-center {
    min-width: 100%;
    min-height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.loader {
    color: white;
}

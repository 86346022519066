.end-turn-btn {
    font-size: 14pt;
    line-height: 40px;
    text-transform: lowercase;
    font-weight: bolder;
    color: #ffffffbf;
    background-color: rgba(0, 0, 0, 0.1);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.end-turn-votes {
    position: absolute;
    top: 0;
    right: 10px;
}

.end-turn-btn-enabled {
    color: white;
}

.end-turn-btn-enabled:hover {
    background-color: #00000030;
    cursor: pointer;
}

.end-turn-btn-enabled:focus {
    color: white;
    outline: none;
    background-color: #00000030;
}

.end-turn-progress {
    width: 100%;
    position: absolute;
    left: 0;
    height: 4px;
    margin-top: -5px;
    margin-left: 7px;
}

@media (max-width: 450px) {
    .end-turn-progress {
        width: 280px;
    }

    .end-turn-btn {
        width: 300px;
    }
}

@media (min-width: 451px) {
    .end-turn-btn {
        width: 420px;
    }

    .end-turn-progress {
        width: 405px;
    }
}

.team-clues {
    padding-right: 6px;
    list-style-type: none;
    padding-bottom: 6px;
}

.clue {
    background-color: #fdfdfd47;
    margin: 2px;
    padding: 2px;
    border-radius: 5px;
    padding-right: 6px;
    margin-right: 10px;
    display: inline-block;
}

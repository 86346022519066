.lobby-settings-icon {
    position: fixed;
    border-radius: 5px 0 0 0;
    background-color: #666666;
    bottom: 0;
    right: 0;
    color: white;
    font-size: 1.5rem;
    z-index: 1;
}

.lobby-settings-icon i {
    margin-right: 10px;
    padding-left: 10px;
}

.lobby-settings-icon i:hover {
    cursor: pointer;
    color: #331c1c;
}

.lobby-settings-tooltip {
    margin-left: 5px;
}

.shpiguni-progress-bar-wrap {
    width: 100%;
    height: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.shpiguni-progress-bar {
    height: 100%;
    background-color: #ffffffba;
}

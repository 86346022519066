.clue-input {
    background-color: rgba(0, 0, 0, 0.1);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    color: white;
}

.clue-input:hover {
    background-color: #00000030;
}

.clue-input:focus {
    outline: none;
    background-color: #00000030;
}

.clue-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff99;
}

.lobby-team {
    border-radius: 5px;
    position: relative;
    overflow-wrap: break-word;
    padding: 10px;
    min-width: 200px;
    min-height: 400px;
}

.lobby-team-bottom-container {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.lobby-team-bottom-action {
    width: 164px;
    height: 38px;
    margin-bottom: 10px;
}

@media (max-width: 1000px) {
    .lobby-team {
        flex-grow: 1;
        flex-shrink: 1;
        margin: 10px;
        width: 0;
    }
}

@media (min-width: 1001px) {
    .lobby-team {
        max-width: 200px;
    }
}

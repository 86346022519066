.game-bottom {
    text-align: center;
    color: white;
    position: relative;
}

.game-bottom-input {
    outline: none;
    text-align: center;
}

@media (max-width: 450px) {
    .game-bottom {
        width: 300px;
        min-height: 40px;
    }

    .game-bottom-input {
        outline: none;
        width: 300px;
        line-height: 40px;
    }
}

@media (min-width: 451px) {
    .game-bottom {
        width: 420px;
        min-height: 50px;
    }

    .game-bottom-input {
        width: 400px;
        line-height: 40px;
    }
}

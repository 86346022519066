.overlay-icon {
    color: #000000db;
    position: absolute;
    opacity: 90%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

@media (min-width: 1600px) {
    .overlay-icon > i {
        font-size: 400px;
    }
}

@media (max-width: 1600px) and (min-width: 1400px) {
    .overlay-icon > i {
        font-size: 340px;
    }
}

@media (max-width: 1400px) and (min-width: 401px) {
    .overlay-icon > i {
        font-size: 300px;
    }
}

@media (max-width: 400px) {
    .overlay-icon > i {
        font-size: 260px;
    }
}

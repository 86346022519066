.overview-table {
    color: white;
    margin-top: 20px;
    max-width: 824px;
}

.overview-table > tbody {
    background-color: #ffefef17;
}

.hoverable-row:hover {
    cursor: pointer;
    background-color: #ffefef31;
}

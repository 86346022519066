.match-history-loading {
    height: 20px;
    position: relative;
    top: -15px;
}

.match-history-title {
    margin-bottom: -32px;
    margin-top: 24px;
    font-weight: bold;
}
